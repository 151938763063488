<template>
</template>

<script setup>
import { notify } from "@kyvg/vue3-notification";

const { query } = useRoute();

const loggedInCookie = useCookie("loggedIn");

const { setPopup } = usePopup();

onMounted(() => {
	if (loggedInCookie.value) {
		notify({
			type: "info",
			title: "You are already logged in",
			text: "Redirecting you to your previous page..."
		})

		if (query.redirect) {
			navigateTo(query.redirect, {
				replace: true,
			});
		}
		return;
	}

	if (query.redirect) {
		notify({
			type: "error",
			title: "Login Required",
			text: "You must login to access this page."
		})
	}

	setTimeout(() => {
		setPopup('LoginPopup');
	}, 50);
});

useHead({
	link: [
		{
			rel: "canonical",
			href: "https://www.minecraftiplist.com",
		},
	],
});

definePageMeta({
	layout: "serverlist",
})
</script>